import type { HTMLFormEventHandler, InputChangeEventHandler } from '@/types/form';
import { useState } from 'react';
import Toast from 'react-hot-toast';
import { Modal } from '@/components/Modal';
import Button from '@/components/Button';
import { Input, PhoneInput } from '@/components/inputs';
import { onlyDefined } from '@/utilities/helper';
import { Grid } from '@/components/Grid';
import { useCreateProperty } from '@/hooks/useCreateProperty';

type CreatePropertyProps = {
  onClose: () => void;
};

const CreateProperty = ({ onClose }: CreatePropertyProps) => {
  const { createProperty, isMutating } = useCreateProperty();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    url: '',
    phoneNumber: '',
    description: '',
    subaccountId: '',
    remmitalPercentage: '',
    vat: '',
  });

  const handleSubmit: HTMLFormEventHandler = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      address,
      url,
      phoneNumber,
      description,
      subaccountId,
      remmitalPercentage,
      vat,
    } = formData;

    createProperty({
      ...onlyDefined({
        name,
        email,
        address,
        url,
        phoneNumber,
        description,
        subaccount_id: `${subaccountId}`,
        remmital_percentage: `${remmitalPercentage}`,
        vat: `${vat}`,
      }),
    }).then((property) => {
      if (property) Toast.success('Property created successfully!');
    });
  };

  const handleInputChange: InputChangeEventHandler = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <Modal show onClose={onClose} title="Create New Property">
      <Grid as="form" spacing="lg" onSubmit={handleSubmit}>
        <Grid grid columns={2} spacing="lg">
          <Input
            label="Property Name"
            placeholder="Enter Property Name"
            name="name"
            type="text"
            validate="required"
            onChange={handleInputChange}
          />
          <Input
            label="Email"
            placeholder="Contact Email"
            name="email"
            type="email"
            validate="required|email"
            onChange={handleInputChange}
          />
          <PhoneInput
            label="Phone Number"
            placeholder="Contact Phone Number e.g +234 90xxxxxxxx"
            name="phoneNumber"
            validate="required|phone_number"
            onChange={handleInputChange}
          />
          <Input
            label="Property URL"
            placeholder="URL e.g https://---.com"
            name="url"
            type="url"
            onChange={handleInputChange}
          />

          <Input
            label="Address"
            placeholder="Enter Property Address"
            name="address"
            type="text"
            validate="required"
            onChange={handleInputChange}
          />
          <Input
            label="Description"
            placeholder="Some short description"
            name="description"
            type="textarea"
            onChange={handleInputChange}
            value={formData.description}
          />
          <Input
            label="Subaccount ID"
            placeholder="Flutterwave Subaccount ID"
            name="subaccountId"
            type="text"
            validate="required"
            onChange={handleInputChange}
          />
          <Input
            label="Remmital (%)"
            placeholder="Flutterwave Remmital (%)"
            name="remmitalPercentage"
            type="number"
            validate="required"
            pattern="[0-9]{,3}"
            min={0}
            max={100}
            step="any"
            onChange={handleInputChange}
          />
          <Input
            label="VAT (%)"
            placeholder="VAT (%)"
            name="vat"
            type="number"
            validate="required"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid alignX="center">
          <Button type="submit" value="Create" size="md" width={100} loading={isMutating} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateProperty;
