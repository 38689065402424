import axios from 'axios';
import { isEmail, isMobilePhone, isStrongPassword } from 'validator';
import Toast from 'react-hot-toast';
import { format, formatDistance, isBefore } from 'date-fns';
import { PaymentStatus } from '@/pages/property/Transactions/types';
import type { AdaptedPaymentTrail } from '@/adapters/paymentTrailAdapter';

export const throttle = (fn: () => null, wait: number) => {
  let time = Date.now();

  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

export const injectLink = (link: string) => {
  const typoElt = document.getElementById('typography.js');

  if (typoElt) {
    typoElt.insertAdjacentHTML('afterend', link);
  }
};

export const getFontsStr = (typography: any) => {
  let fontsStr = '';
  if (typography.options.googleFonts) {
    const fonts = typography.options.googleFonts.map((font: any) => {
      let str = '';
      str += font.name.split(' ').join('+');
      str += ':';
      str += font.styles.join(',');

      return str;
    });

    fontsStr = fonts.join('|');
  }

  return fontsStr;
};

export const getFontsLink = (fontsStr: string) => {
  const href = `//fonts.googleapis.com/css?family=${fontsStr}`;
  const link = `<link href="${href}" rel="stylesheet" type="text/css" />`;
  return { link, href };
};

export const injectFonts = (typography: any) => {
  const fontsStr = getFontsStr(typography);
  if (fontsStr) {
    const { link } = getFontsLink(fontsStr);
    injectLink(link);
  }
};

export const isEmpty = (obj: any) => {
  return (
    obj === undefined ||
    obj === null ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0) ||
    (typeof obj === 'string' && obj.trim().length === 0)
  );
};

export const isEmptyObject = (obj: any) => {
  return !(typeof obj === 'object' && Object.keys(obj).length > 0);
};

export const onlyDefined = (obj: any, skip = ['', undefined, 'undefined', NaN]) => {
  return Object.entries(obj).reduce((acc, [key, value]: any) => {
    if (!skip.includes(value)) acc[key] = value;
    return acc;
  }, {} as any);
};

/**
 * encodeB64
 *
 * encode string to base 64 using **`btoa`** library
 * @param {string} str
 * @returns {string}
 */
export const encodeB64 = (str: string) => {
  return btoa(str);
};

const responseHandler = ({ data = {} }, show: boolean | string) => {
  const { response, message, status_code: status, error, ...others }: any = data;
  if (show) {
    if (error) {
      Toast.error(typeof response === 'string' ? response : message || 'Could be your network!');
    } else {
      Toast.success(typeof show === 'string' ? show : message || 'Success');
    }
  }
  return { response, message, status, error, ...others };
};

const errorHandler = ({ response: { data } = { data: {} } }, show: boolean | string) => {
  const { response, message = 'Network Error', error, status_code: status }: any = data;
  if (show) {
    Toast.error(typeof response === 'string' ? response : message || 'Could be your network!');
  }
  return { response, message, status, error };
};

export const get = (url: string, config = {}, show: boolean | string = false) => {
  return axios
    .get(url, { ...config })
    .then((x) => responseHandler(x, show))
    .catch((x) => errorHandler(x, show));
};

export const post = (url: string, body: any, config = {}, show = false) => {
  return axios
    .post(url, body, { ...config })
    .then((x) => responseHandler(x, show))
    .catch((x) => errorHandler(x, show));
};

export const patch = (url: string, body: any, config = {}, show = false) => {
  return axios
    .patch(url, body, { ...config })
    .then((x) => responseHandler(x, show))
    .catch((x) => errorHandler(x, show));
};

export const upload = (url: string, body: any, config = {}, show = false) => {
  return axios
    .post(url, body, { ...config })
    .then((x) => responseHandler(x, show))
    .catch((x) => errorHandler(x, show));
};

export const formatDate = (date: string | number | Date = '', formatStr = 'MMM d yyyy h:ma') => {
  return date ? format(new Date(date), formatStr) : date;
};

export const validator = (value: string, validate = ''): string[] => {
  const opts = validate.split('|');

  const errors: any = opts
    .map((opt) => {
      if (opt === 'required') {
        return (value || '').trim() ? null : 'Field required';
      }

      if (opt === 'email') {
        return isEmail(value) ? null : 'Enter correct email address';
      }

      if (opt === 'phone_number') {
        return isMobilePhone(value, null, { strictMode: true }) ? null : 'Phone number invalid';
      }

      if (opt === 'number') {
        return Number.isNaN(Number(value)) ? 'Enter only numbers' : null;
      }

      // TODO:: Complete this function
      if (opt.startsWith('number')) {
        const [, sep = ','] = opt.split(':');
        const numbers = value.split(sep) || [];
        const validity = numbers
          .map((v) => (isMobilePhone(v.trim(), null, { strictMode: true }) ? null : v.trim()))
          .filter((v) => v);
        return validity.length === 0 ? null : `Invalid phone numbers: ${validity.join(', ')}`;
      }

      if (opt === 'password') {
        return isStrongPassword(value)
          ? null
          : 'Password must contain 1 lowercase, 1 uppercase, 1 number, 1 special character and minimum of 8 characters';
      }
      return null;
    })
    .filter((e) => !!e);

  return errors;
};

const numberFormatterCache: any = {};
export const getNumberFormatter = (fractionDigits: number, options: Intl.NumberFormatOptions = {}) => {
  const { notation, style } = options;
  const cacheKey = `${style}-${notation}-${fractionDigits}`;
  const instance = numberFormatterCache[cacheKey];

  if (instance) {
    return instance;
  }

  const newInstance = new window.Intl.NumberFormat('en-NG', {
    ...options,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    ...(notation === 'compact' && { maximumSignificantDigits: 2 }),
  });
  numberFormatterCache[cacheKey] = newInstance;

  return newInstance;
};

export const toNaira = (
  value: number | string,
  fractionDigits: number | 'auto' = 'auto',
  notation: Intl.NumberFormatOptions['notation'] = 'standard'
) => {
  const decimalDigits: number = fractionDigits === 'auto' ? 2 : fractionDigits;

  // return zero if value cannot be converted to a number
  if (!Number.isFinite(Number(value))) {
    return 0;
  }

  const formatter = getNumberFormatter(decimalDigits, {
    notation,
    currency: 'NGN',
    style: 'currency',
  });

  return formatter.format(value);
};

export const toPercentage = (value: number | string, fractionDigits?: number) => {
  return formatNumber(value, fractionDigits, 'standard', 'percent');
};

export const formatNumber = (
  value: number | string | null,
  fractionDigits: number | 'auto' = 'auto',
  notation: Intl.NumberFormatOptions['notation'] = 'standard',
  style?: Intl.NumberFormatOptions['style']
) => {
  const decimalDigits: number = fractionDigits === 'auto' ? 2 : fractionDigits;

  // return zero if value cannot be converted to a number
  if (!Number.isFinite(Number(value))) {
    return 0;
  }

  const formatter = getNumberFormatter(decimalDigits, { notation, style });

  return formatter.format(value);
};

export const capitalize = (s: string) => `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`;

export const truncateText = (text: string, maxLength: number) => {
  const textLength = text.length;
  const shouldTruncated = textLength > maxLength;

  if (shouldTruncated) return `${text.slice(0, maxLength)}...`;
  return text;
};

export const getTotalTime = (data: AdaptedPaymentTrail) => {
  if (!data || data.length < 1 || !data[0].createdAt) return 0;

  let startTime = new Date(data[0].createdAt);
  let endTime = startTime;
  data.forEach(({ createdAt }) => {
    if (createdAt) {
      const current = new Date(createdAt);
      if (isBefore(current, startTime)) startTime = current;
      if (isBefore(endTime, current)) endTime = current;
    }
  });

  return formatDistance(endTime, startTime, { includeSeconds: true });
};

export const getAllErrors = (data: AdaptedPaymentTrail) => {
  if (!data || data.length < 1) return [];

  return data.filter(({ actionResult }) => actionResult === PaymentStatus.FAIL);
};

export const toSnakeCase = (s: string) => s.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);

// nested object keys to snake case
export const toSnakeCaseObject = (data: any): Record<string, any> => {
  if (!data || typeof data !== 'object') return data;
  if (Array.isArray(data)) return data.map((item: any) => toSnakeCaseObject(item));

  const newObj: any = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (typeof value === 'object') {
      newObj[toSnakeCase(key)] = toSnakeCaseObject(value);
    } else {
      newObj[toSnakeCase(key)] = value;
    }
  });

  return newObj;
};

export const getFullname = (person: Partial<{ firstName: string | null; lastName: string | null }>) => {
  const { firstName = '', lastName = '' } = person;
  return `${firstName} ${lastName}`.trim();
};
