import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  font-weight: 300;

  label {
    font-size: 12px;
    font-weight: 300;
    padding-left: 3px;
  }

  ul li {
    font-size: 12px;
    font-weight: 300;
  }

  input,
  select,
  .MuiOutlinedInput-root,
  .form-control {
    height: 38px;
  }

  .form-control {
    width: 100%;
    background: inherit;
  }

  .MuiAutocomplete-inputRoot {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  textarea {
  }

  input,
  select,
  textarea {
    border-radius: 8px;
    font-weight: 300;
    cursor: pointer;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    // border-color: #2e6ca0 !important;
  }

  fieldset {
    // border: 1px solid #3d70b9;
    top: 0;
    border: none !important;
  }

  > div {
    width: 100%;
  }

  .MuiSelect-select {
    box-sizing: border-box;
    height: 38px;

    &:focus {
      border-radius: 8px;
    }
  }

  .MuiInputBase-root.color-white {
    .MuiInputBase-input {
      color: #ffffff;
    }
  }

  .form-control,
  .MuiInputBase-input {
    font-size: 13px;
  }

  .MuiMenu-list {
    font-size: 12px !important;
  }

  .MuiOutlinedInput-multiline {
    height: auto !important;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start;
    span {
      font-size: 12px;
    }
  }

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;

    .MuiSvgIcon-root {
      color: #4185e9 !important;
    }
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 4px !important;
  }

  .MuiAutocomplete-popper {
    width: auto !important;
    max-width: 420px;
    top: 36px !important;
    transform: none !important;
    .MuiAutocomplete-option {
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
      line-height: 1.5;
      white-space: nowrap;
      font-weight: 400;
      font-size: 0.9rem !important;
    }
  }
`;

export const StyledHint = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 14px;
`;
